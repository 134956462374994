import React, {useState} from 'react';
import {COLORS, Typography} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {useForm} from 'react-hook-form';
import {isFilled} from 'app/components/helpers/ValidationRules';
import {AuthHint} from 'app/components/authProvider/AuthHint';
import {StyledButton} from 'app/styles/content';
import {Info} from 'app/styles/InfoText';
import {IconButton, InputAdornment, TextField} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useSelector} from 'react-redux';
import {selectButtonType} from 'app/hooks/reduxCreateSelectorHooks';

const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RightAlignedAuthHint = styled(AuthHint)`
  &&& {
    display: inline-flex;
    margin-top: 0;
    width: 100%;
    justify-content: flex-end;
  }
`;
const Data = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`;

const CustomInfo = styled(Info)`
  &&& {
    margin-top: 0;
    text-align: left;
  }
`;
const PasswordFormReAuth = ({handleButtonAction, email, infoText, disableButton}) => {
  const buttonType = useSelector(selectButtonType);
  const [showPassword, setShowPassword] = useState(false);
  const {register, handleSubmit, errors} = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = ({password}) => {
    handleButtonAction(password);
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <CustomInfo variant='body1'>{infoText}</CustomInfo>
      <Data>
        <Typography variant='caption1'>E-Mail</Typography>
        <Typography variant='body1'>{email}</Typography>
      </Data>
      <TextField
        variant="standard"
        autoComplete='current-password'
        autoFocus={true}
        color='secondary'
        error={!!errors.password}
        helperText={errors.password ? errors.password.message : ' '}
        id='password-reauth'
        inputProps={{
          tabIndex: '1',
        }}
        inputRef={register({
          validate: isFilled,
        })}
        label='Passwort'
        name='password'
        placeholder='********'
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                tabIndex='1'
                aria-label='Ändern der Sichtbarkeit des Passworts'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                size="large">
                {showPassword ? (
                  <VisibilityOff style={{color: COLORS.darkGrey}} />
                ) : (
                  <Visibility style={{color: COLORS.darkGrey}} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }} />
      <RightAlignedAuthHint linkedText='Passwort vergessen?' linkTo={'/auth/reset-password'} />
      <StyledButton tabIndex='1' justify='center' type={buttonType} label='Anmelden' disabled={disableButton} />
    </Container>
  );
};

export {PasswordFormReAuth};
